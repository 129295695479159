import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import PriceCard from '../PriceCard';

function Plans() {
  return (
    <>
      <div className='container-1200'>
        <PriceCard />
      </div>

      <Footer />
    </>
  );
}

export default Plans;
